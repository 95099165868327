import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <p>© 2024 Jacob V. B. Haap. Icons by <a href="https://icons8.com/" target="_blank" rel="noopener noreferrer">Icons8</a>.</p>
    </div>
  );
}

export default Footer;