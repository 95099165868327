import React from 'react';

function Links() {
  const links = [
    { href: "https://jacobhaap.com/", imgSrc: "/icons/icons8-external-link.svg", text: "jacobhaap.com", class: "social-link-1" },
    { href: "https://gitlab.com/jacobhaap", imgSrc: "/icons/icons8-gitlab.svg", text: "GitLab", class: "social-link-2" },
    { href: "https://www.linkedin.com/in/jacobhaap/", imgSrc: "/icons/icons8-linkedin.svg", text: "LinkedIn", class: "social-link-1" },
    { href: "https://twitter.com/jacobhaap", imgSrc: "/icons/icons8-twitter.svg", text: "Twitter (X)", class: "social-link-2" },
    { href: "https://discord.gg/tQ7PgVMYKu", imgSrc: "/icons/icons8-discord-logo.svg", text: "Discord", class: "social-link-1" },
    { href: "https://instagram.com/jacobhaap", imgSrc: "/icons/icons8-instagram.svg", text: "Instagram", class: "social-link-2" },
    { href: "https://github.com/jacobhaap", imgSrc: "/icons/icons8-github.svg", text: "GitHub", class: "social-link-1" },
    { href: "https://mastodon.social/@iacobus", imgSrc: "/icons/icons8-mastodon.svg", text: "Mastodon", class: "social-link-2" }
  ];

  return (
    <ul>
      {links.map((link, index) => (
        <li key={index}>
          <a href={link.href} target="_blank" rel="noopener noreferrer" className={link.class}>
            <img src={link.imgSrc} className="social-icon" alt={link.text} />
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Links;
