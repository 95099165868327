import { useEffect } from 'react';

const Background = () => {
    useEffect(() => {
      const html = document.documentElement;
      const colors = ['#00859B', '#3E5D58', '#A9431E'];
      let step = 0;
      const gradientSpeed = 0.01;
      const colorIndices = [0, 1, 2, 0];
  
      function updateGradient() {
        const c0_0 = colors[colorIndices[0]];
        const c0_1 = colors[colorIndices[1]];
        const c1_0 = colors[colorIndices[2]];
        const c1_1 = colors[colorIndices[3]];

      const istep = 1 - step;
      const color1 = `rgb(
        ${Math.round(istep * parseInt(c0_0.substring(1, 3), 16) + step * parseInt(c0_1.substring(1, 3), 16))},
        ${Math.round(istep * parseInt(c0_0.substring(3, 5), 16) + step * parseInt(c0_1.substring(3, 5), 16))},
        ${Math.round(istep * parseInt(c0_0.substring(5, 7), 16) + step * parseInt(c0_1.substring(5, 7), 16))}
      )`;

      const color2 = `rgb(
        ${Math.round(istep * parseInt(c1_0.substring(1, 3), 16) + step * parseInt(c1_1.substring(1, 3), 16))},
        ${Math.round(istep * parseInt(c1_0.substring(3, 5), 16) + step * parseInt(c1_1.substring(3, 5), 16))},
        ${Math.round(istep * parseInt(c1_0.substring(5, 7), 16) + step * parseInt(c1_1.substring(5, 7), 16))}
      )`;

      html.style.background = `linear-gradient(-45deg, ${color1}, ${color2})`;

      step += gradientSpeed;
      if (step >= 1) {
        step %= 1;
        colorIndices[0] = colorIndices[1];
        colorIndices[2] = colorIndices[3];
        colorIndices[1] = (colorIndices[1] + 2) % colors.length;
        colorIndices[3] = (colorIndices[3] + 2) % colors.length;
      }
    }

    const intervalId = setInterval(updateGradient, 10);
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default Background;
