import React, { useState } from 'react';

function ProfileImage() {
  const [isFirstImage, setIsFirstImage] = useState(true);

  const toggleImage = () => {
    setIsFirstImage(!isFirstImage);
  };

  return (
    <div className="image-wrapper">
      <img
        src="/Avatar-1200.png"
        className={`profile-img img-1 ${isFirstImage ? 'visible' : ''}`}
        style={{ opacity: isFirstImage ? 1 : 0 }}
        alt="Profile 1"
        onClick={toggleImage}
      />
      <img
        src="/Profile-1200.jpg"
        className={`profile-img img-2 ${!isFirstImage ? 'visible' : ''}`}
        style={{ opacity: !isFirstImage ? 1 : 0 }}
        alt="Profile 2"
        onClick={toggleImage}
      />
    </div>
  );
}

export default ProfileImage;
