import React, { useState, useEffect } from 'react';

function Time() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        let isMounted = true;

        fetch('https://api.j3.cx/time')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (isMounted) {
                    setData(data);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setError(true);
                }
            });

        const interval = setInterval(() => {
            setData(prevData => prevData ? ({ ...prevData }) : prevData);
        }, 1000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, []);

    const getAdjustedTime = () => {
        if (!data) {
            return new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);
        }

        const offsetHours = parseInt(data.utc_offset.split(':')[0], 10);
        const offsetMinutes = parseInt(data.utc_offset.split(':')[1], 10);

        const now = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000);

        now.setHours(now.getHours() + offsetHours);
        now.setMinutes(now.getMinutes() + offsetMinutes);

        return now;
    };

    const getClockEmoji = (hours, minutes) => {
      const twelveHourTime = hours % 12 || 12;
  
      if (twelveHourTime === 12 && minutes < 30) return "🕛";
      if (twelveHourTime === 12 && minutes >= 30) return "🕧";
      if (twelveHourTime === 1 && minutes < 30) return "🕐";
      if (twelveHourTime === 1 && minutes >= 30) return "🕜";
      if (twelveHourTime === 2 && minutes < 30) return "🕑";
      if (twelveHourTime === 2 && minutes >= 30) return "🕝";
      if (twelveHourTime === 3 && minutes < 30) return "🕒";
      if (twelveHourTime === 3 && minutes >= 30) return "🕞";
      if (twelveHourTime === 4 && minutes < 30) return "🕓";
      if (twelveHourTime === 4 && minutes >= 30) return "🕟";
      if (twelveHourTime === 5 && minutes < 30) return "🕔";
      if (twelveHourTime === 5 && minutes >= 30) return "🕠";
      if (twelveHourTime === 6 && minutes < 30) return "🕕";
      if (twelveHourTime === 6 && minutes >= 30) return "🕡";
      if (twelveHourTime === 7 && minutes < 30) return "🕖";
      if (twelveHourTime === 7 && minutes >= 30) return "🕢";
      if (twelveHourTime === 8 && minutes < 30) return "🕗";
      if (twelveHourTime === 8 && minutes >= 30) return "🕣";
      if (twelveHourTime === 9 && minutes < 30) return "🕘";
      if (twelveHourTime === 9 && minutes >= 30) return "🕤";
      if (twelveHourTime === 10 && minutes < 30) return "🕙";
      if (twelveHourTime === 10 && minutes >= 30) return "🕥";
      if (twelveHourTime === 11 && minutes < 30) return "🕚";
      if (twelveHourTime === 11 && minutes >= 30) return "🕦";
  
      return "🕣";
  };

    if (error) {
        return <p>Failed to load Jacob Standard Time.</p>;
    }

    const adjustedTime = getAdjustedTime();
    const hours = adjustedTime.getHours();
    const minutes = adjustedTime.getMinutes();
    const clockEmoji = getClockEmoji(hours, minutes);

    return data ? (
        <h3> {clockEmoji} {adjustedTime.toLocaleTimeString('en-DE')} (Jacob Standard Time)</h3>
    ) : (
        <p>Loading...</p>
    );
}

export default Time;
