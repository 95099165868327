import React from 'react';
import Background from './components/Background';
import ProfileImage from './components/ProfileImage';
import Time from './components/Time';
import Links from './components/Links';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Background />
      <div className="container">
        <div className="column">
          <ProfileImage />
          <h1>Jacob V. B. Haap</h1>
          <h2>🇩🇪🇪🇺 | <i>Berlin, DE</i></h2>
          <hr />
          <Time />
          <hr />
          <b><i>Software Architect. Founder. Traveler.</i></b>
          <p>Greetings fellow humans! I'm Jacob, a human who does stuff. I am a Software Architect specializing in Web3 & Blockchain Technologies.</p>
          <b>iacobus.xyz • iacobus.eth • iacobus.sol</b>
        </div>
        <div className="column">
          <Links />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
